import React from 'react'
import { Container, Nav, Navbar } from 'react-bootstrap'
import './CSS/NavbarStyles.css';
import { Link } from 'react-router-dom';
import { BsArrowBarLeft } from 'react-icons/bs';

const AuthorizedNavbar = ({ logoTitle, themeMode }) => {
    return (
        <div>
            <Navbar sticky='top' id='navbarstyled' collapseOnSelect expand="lg"
                style={{ backgroundColor: themeMode ? 'white' : 'black' }}
            >
                <Container >
                    <Navbar.Brand href="#"
                        style={{ color: themeMode ? 'black' : 'steelblue' }}
                    ><div className='tittleText'>{logoTitle}</div></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" >
                        <Nav className='justify-content-end w-100  ' >
                        <Nav.Link >
                                <Link to={'/'} className='btn btn-link text-info text-decoration-none fs-6 p-0' ><BsArrowBarLeft/>Back</Link>
                            </Nav.Link>
                            <Nav.Link >
                                <Link to={'/'} className='btn btn-link text-info text-decoration-none fs-6 p-0' onClick={()=>localStorage.clear()}>Logout</Link>
                            </Nav.Link>
                           
                        </Nav>
                    </Navbar.Collapse>
                    {/* {JSON.stringify(navbarItems)} */}
                </Container>
            </Navbar >
        </div>
    )
}

export default AuthorizedNavbar