import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import WorkedCompaniesEntry from './WorkedCompaniesEntry';
import { MdAdd, MdAddTask } from 'react-icons/md';
import { BsPlusSquare } from 'react-icons/bs';
import { Modal, Tooltip } from 'antd';

const ExperienceEntry = ({ workedCompanies }) => {
    const [showAddModal, setShowAddModal] = useState(false);
    const [addModalLoading, setAddModalLoading] = useState(false);

    return (
        <div>
            <div >
                <div className='d-flex justify-content-between'>
                    <div className='lead fs-5 m-3'>Worked Companies and Projects:</div>
                    <div>
                        <Tooltip placement='top' title="Add Company (or) Project">
                            <Button className='' size='sm'
                                onClick={() => {setShowAddModal(true);setAddModalLoading(true); setTimeout(()=>{setAddModalLoading(false)}, 1000)}}
                            >
                                <MdAddTask size={18} className='me-1' />ADD
                            </Button>
                        </Tooltip>
                    </div>
                </div>
            </div>
            <div>
                <DataTable columns={workedCompColumns} data={workedCompanies} />
            </div>
            <Modal title={addModalLoading ? 'Loading.....': 'Adding worked Company or Project'} width={700}
                open={showAddModal} 
                loading={addModalLoading}
                onOk={() => setShowAddModal(false)} onCancel={() => setShowAddModal(false)}
                footer={null}
            >
                <Tabs
                    // defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mt-3"
                >
                    <Tab eventKey="basicDetails" title="Worked Companies">
                        <WorkedCompaniesEntry setShowAddModal={setShowAddModal}/>
                    </Tab>
                    <Tab eventKey="Experience" title="Worked Projects">

                    </Tab>
                </Tabs>
            </Modal>
        </div>
    )
}

var workedCompColumns = [
    {
        name: 'Sl. No',
        selector: (row, index) => index + 1,
    },
    {
        name: 'Company Name',
        selector: row => row.company_name,
    },
    {
        name: 'Designation',
        selector: row => row.designation,
    },
    {
        name: 'From Date',
        selector: row => row.from_date,
    },
    {
        name: 'To Date',
        selector: row => row.to_date,
    },
    {
        name: 'No. of Projects',
        selector: row => row.numberof_projects,
    },
    {
        name: 'Company Seq.',
        selector: row => row.comp_seq,
    },
    {
        name: 'Company Code',
        selector: row => row.company_code,
    },
    {
        name: 'Action',
        selector: row => row.company_code,
    },
    {
        name: 'Action',
        selector: row => row.company_code,
    },

];

{/* <Table responsive >
                    <thead>
                        <tr>
                            <th>Sl. No.</th>
                            <th>Company Name</th>
                            <th>Designation</th>
                            <th>From Date</th>
                            <th>To Date</th>
                            <th>No. of Projects</th>
                            <th>Company Seq. No</th>
                            <th>Company Code </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            workedCompanies?.map((item, index) => (
                                <tr>
                                    <td>{index+1}</td>
                                    <td>{item?.company_name}</td>
                                    <td>{item?.designation}</td>
                                    <td>{item?.from_date}</td>
                                    <td>{item?.to_date}</td>
                                    <td>{item?.numberof_projects}</td>
                                    <td>{item?.comp_seq}</td>
                                    <td>{item?.company_code}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table> */}


export default ExperienceEntry