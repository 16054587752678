import React from 'react';
import { NavLink as BsNavlink, Button, Container, Nav, Navbar } from 'react-bootstrap';
import { BsPersonCircle } from 'react-icons/bs';
import { MdLightMode, MdNightlight } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import './CSS/NavbarStyles.css';

const NavbarHeader = ({ setThemeMode, themeMode, navbarItems, logoTitle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [navbarItems, setNavbarItems] = useState(navbarItemsUtils);

    const fontColorChange = () => {
        return themeMode ? 'black' : 'lightgrey';
    }

    return (
        <Navbar sticky='top' id='navbarstyled' collapseOnSelect expand="lg" style={{ backgroundColor: themeMode ? 'white' : 'black' }}>
            <Container >
                <Navbar.Brand href="#" style={{ color: themeMode ? 'black' : 'steelblue' }}><div className='tittleText'>{logoTitle}</div></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" >
                    <Nav  className='justify-content-end w-100  ' >
                        {
                            navbarItems?.map((item, index) => {
                                if (item?.type === 'link') {
                                    return <BsNavlink className={`${item?.className} nav-link m-0`}
                                        href={item?.path} key={index} style={{ color: fontColorChange() }}>
                                        <div className='mt-1 m-1'>{item?.label}</div>
                                    </BsNavlink>
                                } else if (item?.type === 'button') {
                                    return <Nav.Item key={index}>
                                        <button className='btn btn-text' onClick={() => {
                                            dispatch({ type: 'THEME_MODE', payload: !themeMode })
                                            setThemeMode(!themeMode)
                                        }}>{
                                                item?.label &&
                                                <span className='text-secondary' ><small>{item?.label}</small></span>
                                            }
                                            {
                                                themeMode ?
                                                    <MdLightMode size={22} style={{ color: 'steelblue' }} />
                                                    :
                                                    <MdNightlight size={20} style={{ color: 'steelblue' }} />
                                            }</button>
                                    </Nav.Item>
                                } else if (item?.type === 'reg') {
                                    return <Nav.Item key={index}>
                                        <Button className={`${item?.styles?.linkType}`} href={item?.path} >{item?.label}</Button>
                                    </Nav.Item>
                                }else if(item?.type === 'navlink'){
                                    return <NavLink className={`${item?.className} nav-link m-0`}
                                        to={item?.path} key={index} style={{ color: fontColorChange() }}>
                                    <div className='mt-1 m-1'>{item?.label}</div>
                                </NavLink>
                                }else if (item?.type === 'admin'){
                                    return <Nav.Item key={index}>
                                    <BsPersonCircle  size={20} className='m-2 text-secondary' style={{cursor: 'pointer'}}
                                        
                                        onClick={()=> {
                                            if(localStorage.getItem('token')){
                                                navigate('/adminboard')
                                            }else{
                                                navigate('/login')
                                            }
                                        }}
                                    />
                                    {/* <Button className={`${item?.styles?.linkType}`} href={item?.path} >{item?.label}</Button> */}
                                </Nav.Item>
                                }
                            })
                        }
                    </Nav>
                </Navbar.Collapse>
                {/* {JSON.stringify(navbarItems)} */}
            </Container>
        </Navbar >
    )
}

export default NavbarHeader


    // window.onscroll = () => onScrollFuntionForNav();

    // const onScrollFuntionForNav = () => {
    //     var elements = document.getElementsByTagName('section');
    //     var bscroll = document.body.scrollTop;
    //     for (var i = 0; i < elements.length; i++) {
    //         var sHeight = elements[i].offsetHeight;
    //         var offsets = elements[i].offsetTop;
    //         console.log('bscroll , sheight, offsets, ', bscroll, sHeight, offsets)
    //         if (bscroll > offsets && bscroll < offsets + sHeight) {
                
    //             elements[i].className = 'section active';
    //             console.log('         document elements        ', elements[i])

    //         } else {
                
    //             elements[i].className = 'active';
    //             setNavbarItems(navbarItems.map(item => {
    //                 if (item[`path`].split('#')[1] == elements[i].id) {
    //                     console.log('true')
    //                     item['className'] = 'active'
    //                 }
    //                 return item
    //             }))
    //             console.log('         document elements        ', navbarItems)


    //         }
    //     }
    //     // console.log('         document elements        ', elements)
    // }