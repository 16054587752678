import React, { useState } from 'react';
import { Button, Container, Tab, Tabs } from 'react-bootstrap';
import AuthorizedNavbar from '../main/AuthorizedNavbar';
import BasicDetails from './BasicDetails';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import ExperienceEntry from './ExperienceEntry';

const AdminBoard = () => {
    const portfolioDetails = useSelector(state => state?.portfolioState) || null;

    return (
        <div className='vh-100'>
            {/* <NavbarHeader navbarItems={authorizedNavItems} themeMode={''} /> */}
            <AuthorizedNavbar logoTitle={'Admin Page '} />
            <Container>
                {/* {JSON.stringify(portfolioDetails.personDetails)} */}
                <Tabs
                    // defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="basicDetails" title="Basic Details">
                        <BasicDetails personDetails={portfolioDetails.personDetails[0] || ''} />
                    </Tab>
                    <Tab eventKey="Experience" title="Experience">
                        <ExperienceEntry workedCompanies={portfolioDetails?.workedCompanies}/>
                    </Tab>
                    <Tab eventKey="Education" title="Education" >
                        Tab content for Contact
                    </Tab>
                </Tabs>


            </Container>
        </div>
    )
}


const items = [
    {
        key: '1',
        label: 'Tab 1',
        children: 'Content of Tab Pane 1',
    },
    {
        key: '2',
        label: 'Tab 2',
        children: 'Content of Tab Pane 2',
    },
    {
        key: '3',
        label: 'Tab 3',
        children: 'Content of Tab Pane 3',
    },
];

export default AdminBoard
