import Login from "../auth/Login";
import AdminBoard from "../DataEntry/AdminBoard";
import LoginExample from "../extras/LoginExample";
import CoverPage from "../main/CoverPage";
import WelcomePage from "../main/WelcomePage";
import PDFsample from "../resumes/extras/PDFsample";
import ResumeTemplateSample from "../resumes/templates/ResumeTemplateSample";
import Stopwatch from "../tasks/Stopwatch";
import TempWatch from "../tasks/TempWatch";

export const CustomRoutes = [
    {
        path: '/',
        element: <WelcomePage/>,

    },
    // {
    //     path: '/rajeshprofile',
    //     element: <WelcomePage />,
    // },
    {
        path: '/resumetemplatesample',
        element: <ResumeTemplateSample />,
    },
    {
        path: '/samplepdfreact',
        element: <PDFsample />
    },
    {
        path: '/stopwatch',
        element: <Stopwatch />
    },
    {
        path: '/tempwatch',
        element: <TempWatch />
    },
    {
        path: '/loginexample',
        element: <LoginExample />
    },
    {
        path: '/adminboard',
        element: <AdminBoard />
    },
    {
        path: '/login',
        element: <Login />
    }

]